import React, { useEffect, useState } from 'react';
import { ButtonMUI, DialogMUI, InputMUI, SelectComponent, TabItem, Table, Tabs } from '../../shared';
import styles from './Support.module.scss';
import additionalStyles from '../Audio/Audio.module.scss';
import adverStyles from '../Advertising/Advertising.module.scss'
import modalStyles from '../Audio/StatisticSidebar/StatisticSidebar.module.scss';
import albumsStyles from '../Audio/Album/Album.module.scss';
import { classList, objectToQueryString, prettyScroll } from '../../helpers/functions';
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'
import { ReactComponent as ExpandSupport } from '../../assets/icons/expand_support.svg'
import { useDispatch, useSelector } from 'react-redux';
import { closePlaylistModal, getPlaylistTracks, getSupportContent, setClaimStatus } from './storage/supportActions';
import { renderAlbumInfo, renderArtists } from '../Audio/Audio';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as NewStatus } from '../../assets/icons/new_status.svg'
import { ReactComponent as AprovedStatus } from '../../assets/icons/approved_status.svg'
import { ReactComponent as RejectedStatus } from '../../assets/icons/rejected_status.svg'
import { ReactComponent as ApproveBTN } from '../../assets/icons/approve_btn.svg'
import { ReactComponent as RejectBTN } from '../../assets/icons/reject_btn.svg'
import { ClickAwayListener } from '@material-ui/core';
import { setPostModal } from '../Users/storage/usersActions';
import { PostModal } from '../Users/Feed';

import noPlaylistImg from '../../assets/images/nonAvatars/playlist.svg'
import noAlbumImg from '../../assets/images/nonAvatars/album.svg'
import noTrackImg from '../../assets/images/nonAvatars/track.svg'

const tracksPageSize = 15

const Support = () => {
  const dispatch = useDispatch()
  const { ...support } = useSelector(({ support }) => support)
  const [parameters, setParameters] = useState(initialParameters)
  const [openPlaylist, setOpenPlaylist] = useState({ status: false, target: null })
  const [contextMenu, setContextMenu] = useState(false)
  const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
  const [btnLoad, setBtnLoad] = useState(false)
  const [expandRow, setExpandRow] = useState(null)
  const [tab, setTab] = useState('claims')

  const paramsSetter = (key, value) => {
    setParameters((prev) => ({ ...prev, [key]: value }))
  }

  const openDialog = (type) => {
    setDialogStatus({ status: true, type: type, target: support[tab]?.results.find(el => el.id === contextMenu) })
    contextMenuActions('close', contextMenu)
  }

  const contextMenuActions = (action, id) => {
    if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
    else if ('open') setContextMenu(prev => prev === id ? false : id)
  }

  const submitDialog = () => {
    setBtnLoad(true)
    dispatch(setClaimStatus(dialogStatus.target.id, dialogStatus.type))
      .then(res => {
        setBtnLoad(false)
        if (res.type.includes('_SUCCESS')) {
          setDialogStatus(prev => ({ ...prev, status: false }))
        }
      })
  }

  useEffect(() => {
    setParameters(initialParameters)
    dispatch(getSupportContent(tab, objectToQueryString(parameters)))
    setExpandRow(null)
  }, [tab])

  useEffect(() => {
    if (!dialogStatus.status) setTimeout(() => setDialogStatus(prev => ({ ...prev, type: null, target: null })), 250)
  }, [dialogStatus.status])

  useEffect(() => {
    if (!openPlaylist.status) setTimeout(() => {
      setOpenPlaylist(prev => ({ ...prev, target: null }))
      dispatch(closePlaylistModal())
    }, 250)
  }, [openPlaylist.status])

  let columns = [
    {
      name: 'Date & time',
      value: 'date',
      width: 20,
      headerClass: styles.noLeftPadding,
      dataClass: styles.noLeftPadding,
      renderKey: 'created_date',
      render: date => {
        return <div className={'cellText'}>{!!date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-'}</div>
      }
    },
    {
      name: 'User',
      value: 'name',
      width: 30,
      renderKey: `author`,
      render: author => <Link to={`/main/users/${author.id}`} className={additionalStyles.nameWrapper}>{
        renderArtists({
          //...author,
          is_verified: author.is_verified,
          name: author?.name?.replaceAll(' ', '')
            ? author.name
            : author.full_name.replaceAll(' ', '')
              ? author.full_name
              : author.username
                ? `@${author.username}`
                : author.email,
          username: author.email ? author.email : author.username ? `@${author.username}` : '-'
        })
      }
      </Link>
    },
    {
      name: tab === 'claims' ? 'Claim' : 'Email',
      value: tab === 'claims' ? null : 'email',
      width: 40,
      renderKey: ['author', 'album', 'post', 'playlist', 'track', 'user'],
      render: ({ author, album, post, playlist, track, user }) => (
        tab === 'claims'
          ? !!track
            ? <Link to={`/main/audio/track/${track.id}`} className={additionalStyles.nameWrapper}>
              <img src={track.image || noTrackImg} alt={track.name} />
              <div className={additionalStyles.info}>
                <p>{track.name}</p>
                {renderAlbumInfo({
                  version: track.version,
                  album_name: track.artists.find(el => el.is_main).name && track.artists.find(el => el.is_main).name.replaceAll(' ', '')
                    ? track.artists.find(el => el.is_main).name
                    : `@${track.artists.find(el => el.is_main).username}`,
                  length: track.length
                }, 'tracks')}
              </div>
            </Link>
            : !!album
              ? <Link to={`/main/audio/album/${album.id}`} className={additionalStyles.nameWrapper}>
                <img src={album.image || noAlbumImg} alt={album.name} />
                <div className={additionalStyles.info}>
                  <p>{album.name}</p>
                  {renderAlbumInfo({ version: album.version, album_name: album.artist.full_name, length: album.length, tracks_count: album.tracks_count }, 'albums')}
                </div>
              </Link>
              : !!playlist
                ? <div onClick={() => setOpenPlaylist({ status: true, target: playlist })} className={additionalStyles.nameWrapper}>
                  <img src={playlist.image || noPlaylistImg} alt={playlist.name} />
                  <div className={additionalStyles.info}>
                    <p>{playlist.name}</p>
                    {renderAlbumInfo({
                      version: playlist?.version,
                      album_name: playlist.creator.full_name,
                      length: playlist.length,
                      tracks_count: playlist.tracks_count,
                      creator: playlist.creator
                    }, 'albums')}
                  </div>
                </div>
                : !!post
                  ? <button onClick={() => dispatch(setPostModal(post.id))} className={styles.postButton}>
                    {renderArtists({
                      ...post.author,
                      full_name: !!post.author.full_name && !!post.author.full_name.replaceAll(' ', '')
                        ? post.author.full_name
                        : post.author.username
                          ? `@${post.author.username}`
                          : '-',
                      username: 'posted a post'
                    })}
                  </button>
                  : !!user
                    ? <Link to={`/main/users/${user.id}`} className={additionalStyles.nameWrapper}>
                      {renderArtists({
                        is_verified: user.is_verified,
                        name: user.name.replaceAll(' ', '')
                          ? user.name
                          : user.full_name.replaceAll(' ', '')
                            ? user.full_name
                            : user.username
                              ? `@${user.username}`
                              : user.email,
                        //name: !!user.full_name && !!user.full_name.replaceAll(' ', '') ? user.full_name : '-',
                        username: user.email ? user.email : user.username ? `@${user.username}` : '-',
                        //...user
                      })}
                    </Link>
                    : null
          : <div className={'cellText'}>{author.email}</div>
      )
    },
    {
      name: '',
      value: null,
      width: 10,
      headerClass: styles.noRightPadding,
      dataClass: styles.noRightPadding,
      renderKey: ['id', 'status'],
      render: ({ id, status }) => (
        <div className={styles.additionalButtons}>
          <div onClick={() => setExpandRow(prev => prev === id ? null : id)} className={classList(styles.expander, { [styles.active]: expandRow === id })}><ExpandSupport /></div>
          {tab === 'claims' && (<>
            <div className={adverStyles.moreActionsWrapper}>
              <ClickAwayListener onClickAway={() => contextMenuActions('close', id)}>
                <div className={adverStyles.moreActions}>
                  <button className={classList(adverStyles.moreActions__btn, { [adverStyles.active]: contextMenu === id })} onClick={() => contextMenuActions('open', id)}>
                    <span />
                    <span />
                    <span />
                  </button>
                  {contextMenu === id && <div className={adverStyles.moreActions__container}>
                    {status !== 'approved' && <button
                      className={classList(adverStyles.actionButtons)}
                      onClick={() => openDialog('approved')}
                    >
                      <ApproveBTN /> Approve
                    </button>}
                    {status !== 'rejected' && <button
                      className={classList(adverStyles.actionButtons, adverStyles.deleteAction)}
                      onClick={() => openDialog('rejected')}
                    >
                      <RejectBTN /> Reject
                    </button>}
                  </div>}
                </div>
              </ClickAwayListener>
            </div>
          </>)}
        </div>
      )
    },
    {
      name: '',
      value: null,
      width: 100,
      headerClass: classList(styles.expander__header, styles.noLeftPadding, styles.noRightPadding),
      dataClass: classList(styles.noLeftPadding, styles.noRightPadding, styles.expanderTD),
      renderKey: ['id', 'text'],
      render: ({ id, text }) => {
        return <div
          onClick={() => setExpandRow(prev => prev === id ? null : id)}
          className={classList(styles.expander__body, { [styles.active]: expandRow === id })}
        >
          <div>
            <div className={styles.expander__inner}>
              <p>{tab === 'claims' ? 'Reason' : 'Message'}</p>
              <span>{text}</span>
            </div>
          </div>
        </div>
      }
    },
  ]

  if (tab === 'claims') {
    columns[0].width = 15;
    columns[1].width = 25;
    columns[2].width = 25;
    columns.splice(3, 0, {
      name: 'Status',
      value: null,
      width: 20,
      renderKey: `status`,
      render: status => <div className={'cellText'}>
        <div className={classList(styles.status, styles[status])}>
          {statusesIcons[status]}
          {status}
        </div>
      </div>
    })
    columns[4].width = 15;
  }

  return (
    <main className={`${styles.support} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.supportInner} container`}>
        <h1>Support</h1>
        <div className={styles.tabWrap}>
          <Tabs className={styles.tabs} defaultIndex={tab} onTabClick={setTab}>
            <TabItem label='Claims' index='claims' />
            <TabItem label='Feedbacks' index='feedbacks' />
          </Tabs>
        </div>
        <div className={`inner-wrap ${styles['inner-wrap']}`}>
          <div className={styles.filters}>
            <InputMUI
              className={styles.search}
              id='search'
              type='text'
              placeholder={
                tab === 'claims'
                  ? 'Search by user or claim...'
                  : 'Search by user name or email...'
              }
              startAdornment={<SearchIcon />}
              size='small'
              name={`${tab}_search`}
              value={parameters.search}
              onChange={({ target: { value } }) => paramsSetter('search', value)}
            />
            {tab === 'claims' && <>
              <SelectComponent
                options={claimTypes}
                value={parameters.type || claimTypes[0]}
                onChange={e => paramsSetter('type', e)}
                selectWithLabel={'Claim type'}
              />
              <SelectComponent
                options={statuses}
                value={parameters.status || statuses[0]}
                onChange={e => paramsSetter('status', e)}
                selectWithLabel={'Status'}
              />
            </>
            }
          </div>
          <Table
            action={({ queryString }) => dispatch(getSupportContent(tab, queryString))}
            disableRedux
            params={parameters}
            setParams={setParameters}
            columns={columns}
            rows={support[tab]?.results}
            rowsCount={support[tab]?.count}
            loading={support[`${tab}_loading`]}
            classes={{ row: styles.rowWithExpand, header: styles.headerWithExpand }}
            withExpand
            noFirstActionFire
          />
        </div>
      </div>
      <PostModal />
      <DialogMUI
        open={!!openPlaylist.status}
        onClose={() => setOpenPlaylist(prev => ({ ...prev, status: false }))}
        maxWidth={'md'}
        contentClass={modalStyles.commentsDialogWrapper}
      >
        <div className={classList(modalStyles.commentsDialog, styles.commentsDialog)}>
          <div className={modalStyles.heading}>
            <img src={openPlaylist?.target?.image || noPlaylistImg} alt={openPlaylist?.target?.name} />
            <div className={modalStyles.audioInfo}>
              <p>{openPlaylist?.target?.name}</p>
              {!!openPlaylist.target && renderAlbumInfo(openPlaylist.target, `albums`)}
            </div>
          </div>
          <TrackList
            openPlaylist={openPlaylist}
          />
        </div>
      </DialogMUI>
      <DialogMUI
        open={dialogStatus.status && !!dialogStatus.target}
        onClose={() => setDialogStatus(prev => ({ ...prev, status: false }))}
        maxWidth={'xs'}
      >
        <>
          <h2 className={additionalStyles.dialogTitle}>{dialogStatus.type === 'approved' ? 'Approve claim' : 'Reject claim'}</h2>
          <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
            <p className={additionalStyles.desc}>Confirm that you want to {dialogStatus.type === 'approved' ? 'approve' : 'reject'} this claim. The user will be notified of your decision.</p>
            <div className={additionalStyles.separator} />
            <div className={additionalStyles.button_wrapper}>
              <ButtonMUI
                variant='contained'
                size="small"
                className={additionalStyles.dialogButtons}
                onClick={submitDialog}
                loading={btnLoad}
              >
                {dialogStatus.type === 'approved' ? 'approve' : 'reject'}
              </ButtonMUI>
              <ButtonMUI
                variant='contained'
                size="small"
                className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
              >
                Cancel
              </ButtonMUI>
            </div>
          </div>
        </>
      </DialogMUI>
    </main>
  );
};

const TrackList = ({ openPlaylist }) => {
  const dispatch = useDispatch()
  const { playlistTacks_loading, playlistTacks } = useSelector(({ support }) => support)
  const [tracksPage, setTracksPage] = useState(false)

  useEffect(() => {
    if (!!openPlaylist?.target?.id) dispatch(getPlaylistTracks(openPlaylist?.target?.id, tracksPageSize, tracksPage))
  }, [tracksPage])

  const scrollTrackList = ({ target: { clientHeight, scrollTop, scrollHeight } }) => {
    if (clientHeight + scrollTop >= scrollHeight && !!playlistTacks.next && playlistTacks.next.split('page=')[1].split('&')[0] && !playlistTacks_loading) {
      setTracksPage(playlistTacks.next.split('page=')[1].split('&')[0])
    }
  }

  return <div className={classList(modalStyles.commentsList, { [styles.modalLoad]: playlistTacks_loading }, styles.commentsList)} onScroll={scrollTrackList} ref={e => prettyScroll(e, false)}>
    {playlistTacks_loading
      ? <CircularProgress className={styles.modalLoad__progress} />
      : playlistTacks.results.map((el, idx) => <div key={el.id} className={classList(albumsStyles.trackItem, styles.trackItem)}>
        <Link to={`/main/audio/track/${el.id}`} className={classList(albumsStyles.trackItem__info, styles.trackItem__info, 'w-60')}>
          <span>{idx + 1}</span>
          <img className={albumsStyles.trackItem__image} src={el.image} alt={el.name} />
          <div className={albumsStyles.trackItem__name}>
            <p>{el.name}</p>
            {renderAlbumInfo({ ...el, album_name: el.artists?.find(artist => artist.is_main)?.name || '' }, `tracks`)}
          </div>
        </Link>
        <div className='w-40'>
          {el.album?.name || '-'}
        </div>
      </div>)}
  </div>
}

const claimTypes = [
  {
    label: 'All claims',
    value: null
  },
  {
    label: 'Users',
    value: 'users'
  },
  {
    label: 'Posts',
    value: 'posts'
  },
  {
    label: 'Albums',
    value: 'albums'
  },
  {
    label: 'Tracks',
    value: 'tracks'
  },
  {
    label: 'Playlists',
    value: 'playlists'
  },
]

const statuses = [
  {
    label: 'All statuses',
    value: null
  },
  {
    label: 'New',
    value: 'new'
  },
  {
    label: 'Approved',
    value: 'approved'
  },
  {
    label: 'Rejected',
    value: 'rejected'
  }
]

const statusesIcons = {
  new: <NewStatus />,
  approved: <AprovedStatus />,
  rejected: <RejectedStatus />
}

const initialParameters = {
  page_size: 10,
  ordering: '-date', // name | -name | date | -date | email | -email
  type: null, // users | posts | albums | tracks | playlists
  status: null, // new | approved | rejected
  search: '',
}

export default Support;
