import { useEffect, useState } from 'react';
import { classList, prettyScroll } from '../../helpers/functions';
import { ButtonMUI, CheckboxMUI, DialogMUI, InputMUI, RadioMUI, SelectComponent, TabItem, Tabs } from '../../shared';
import styles from './PagesContent.module.scss';
import additionalStyles from '../Audio/Audio.module.scss';
import { ReactComponent as Globus } from '../../assets/icons/globus.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg'
import { ReactComponent as SelectedIcon } from '../../shared/RadioMUI/icons/selected.svg'
import { ReactComponent as UnselectedIcon } from '../../shared/RadioMUI/icons/unselected.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'
import { copyContent, createGenre, getAvailableTracks, getContent, setCountryAuto, setGenresAuto, setTracks } from './storage/pagesContentActions';
import { useDispatch, useSelector } from 'react-redux';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useParams } from 'react-router-dom';
dayjs.extend(utc)

const genreTypes = [
    {
        label: 'Music',
        value: false
    },
    {
        label: 'Non-music',
        value: true
    }
]

export const SetAutoModal = ({ dialogStatus, setDialogStatus, btnLoad, submitDialog, tab }) => <DialogMUI
    open={dialogStatus.status}
    onClose={() => setDialogStatus(prev => ({ ...prev, status: false }))}
    maxWidth={'xs'}
>
    <>
        <h2 className={additionalStyles.dialogTitle}>{dialogStatus.type === 'all' || dialogStatus.type === 0 ? 'Set ‘Automatic’ for all' : 'Set ‘Automatic’'}</h2>
        <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
            <p className={additionalStyles.desc}>
                Confirm that you want to enable "Automatic" mode to generate all top songs rankings for {
                    dialogStatus.type === 'all' || dialogStatus.type === 0
                        ? `all ${tab === 'countries' ? 'regions' : 'genres'}`
                        : `this ${tab === 'countries' ? 'region' : 'genre'}`
                }.
            </p>
            <div className={additionalStyles.separator} />
            <div className={additionalStyles.button_wrapper}>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={additionalStyles.dialogButtons}
                    onClick={submitDialog}
                    loading={btnLoad}
                    disabled={btnLoad || !dialogStatus.status}
                >
                    Confirm
                </ButtonMUI>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                    onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
                >
                    Cancel
                </ButtonMUI>
            </div>
        </div>
    </>
</DialogMUI>

export const CreateGenreModal = ({ open, onCLose }) => {
    const dispatch = useDispatch()
    const [values, setValues] = useState({ name: '', non_music: false })
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        if (open) setValues({ name: '', non_music: false })
    }, [open])

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(createGenre(values))
            .then(({ type }) => {
                setBtnLoad(false)
                if (type.includes('_SUCCESS')) {
                    onCLose()
                }
            })
    }

    return <DialogMUI
        open={open}
        onClose={onCLose}
        maxWidth={'xs'}
    >
        <>
            <h2 className={additionalStyles.dialogTitle}>New genre</h2>
            <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
                <div className={styles.addGenreWrapper}>
                    <div className={additionalStyles.inputLabel}>
                        <p className={additionalStyles.inputLabel__title}>Name</p>
                        <div className={additionalStyles.inputLabel__wrapper}>
                            <InputMUI
                                className={styles.nameField}
                                id='name'
                                type='text'
                                placeholder='Enter the genre name'
                                value={values.name}
                                onChange={({ target: { value } }) => setValues(prev => ({ ...prev, name: value }))}
                            />
                        </div>
                    </div>
                    <div className={additionalStyles.inputLabel}>
                        <p className={additionalStyles.inputLabel__title}>Category</p>
                        <div className={additionalStyles.inputLabel__wrapper}>
                            <SelectComponent
                                options={genreTypes}
                                value={genreTypes.find(el => el.value === values.non_music)}
                                placeholder={'Select option(s)'}
                                onChange={e => setValues(prev => ({ ...prev, non_music: e.value }))}
                                size={'big'}
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className={additionalStyles.separator} />
                <div className={additionalStyles.button_wrapper}>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={additionalStyles.dialogButtons}
                        onClick={submitDialog}
                        loading={btnLoad}
                        disabled={btnLoad || !values.name.length}
                    >
                        Confirm
                    </ButtonMUI>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                        onClick={onCLose}
                    >
                        Cancel
                    </ButtonMUI>
                </div>
            </div>
        </>
    </DialogMUI>
}

export const AddTracksModal = ({ open, onClose, selectedTracks, setSelectedTracks }) => {
    const { pageType, pageID } = useParams()
    const isCountriesPageType = pageType === 'countries';
    const dispatch = useDispatch();
    const [search, setSearch] = useState('')
    const [localSelectedTracks, setLocalSelectedTracks] = useState(selectedTracks)
    const { availableTracks, innerContent } = useSelector(({ pagesContent }) => pagesContent)
    const cursor = availableTracks?.next?.split('cursor=')[1]?.split('search=')[0]

    useEffect(() => {
        setLocalSelectedTracks(selectedTracks)
    }, [open])

    const searchAction = value => dispatch(getAvailableTracks({ search: value }))

    const onScrollEndEvent = () => dispatch(getAvailableTracks({ search: search, cursor: cursor }))

    const save = () => {
        setSelectedTracks(localSelectedTracks)
        dispatch(setTracks(localSelectedTracks))
        onClose(false)
    }

    return (<DialogMUI
        open={open}
        onClose={() => onClose(false)}
        maxWidth={'xs'}
        contentClass={styles.availableTracksContent}
    >
        <>
            <h2 className={classList(additionalStyles.dialogTitle, 'mb-24', 'important')}>Add tracks</h2>
            <div className={classList(additionalStyles.dialogWrapper, styles.addTraksDialogWrapper)}>
                <div className={styles.saveBlock}>
                    <div className={styles.nameWrapper}>{
                        isCountriesPageType && (
                            <div>
                                {pageID === '00'
                                    ? <Globus />
                                    : <span className={`fi fi-${pageID.toLowerCase()} fis`} />
                                }
                            </div>)
                    }
                        <div className={styles.info}>
                            <p>{innerContent.name}</p>
                            <span>{localSelectedTracks.length}/50 tracks</span>
                        </div>
                    </div>
                    <ButtonMUI
                        size='small'
                        xs
                        className={classList(additionalStyles.actionButtons)}
                        onClick={save}
                        disabled={localSelectedTracks.length > 50}
                    >
                        Save
                    </ButtonMUI>
                </div>
                <div className={additionalStyles.separator} />
                <div className={styles.searchInputWrapper}>
                    <p className={classList(additionalStyles.desc, additionalStyles.small)}>Use the search or select suitable tracks from the list</p>
                    <InputMUI
                        className={classList(styles.search, styles.searchInput)}
                        id='search'
                        type='text'
                        placeholder='Search by name or artist...'
                        startAdornment={<SearchIcon />}
                        size='small'
                        name={'country_search'}
                        onChange={({ target: { value } }) => {
                            setSearch(value)
                            searchAction(value)
                        }}
                    />
                </div>
                <div className={styles.availableTracks} ref={e => prettyScroll(e, false, onScrollEndEvent)}>
                    {availableTracks && availableTracks.results && availableTracks.results.length > 0
                        ? availableTracks.results.map(el => (
                            <label className={styles.availableTracksItem} key={el.id}>
                                <img src={el.image} alt={el.name} />
                                <div className={styles.info}>
                                    <p>{el.name}</p>
                                    <span>{el.artists.find(el => el.is_main).name}{' • '}{dayjs(el.length).format().slice(14, 19)}</span>
                                </div>
                                <CheckboxMUI
                                    checked={localSelectedTracks.some(selected => selected.id === el.id)}
                                    onChange={() => setLocalSelectedTracks(prev => {
                                        if (prev.some(selected => selected.id === el.id)) {
                                            return prev.filter(selected => selected.id !== el.id)
                                        } else {
                                            return [...prev, el]
                                        }
                                    })}
                                    icon={<UnselectedIcon />}
                                    checkedIcon={<SelectedIcon />}
                                    className={styles.checkbox}
                                />
                            </label>
                        ))
                        : <div className={styles.availableTracksEmpty}>
                            <p>Couldn’t find the track</p>
                            <span>Сorrect the entered data or try another track name</span>
                        </div>}
                </div>
            </div>
        </>
    </DialogMUI >)
}

export const StopEditingModal = ({ open, onClose, exit }) => <DialogMUI
    open={open}
    onClose={onClose}
    maxWidth={'xs'}
>
    <>
        <h2 className={additionalStyles.dialogTitle}>Stop editing</h2>
        <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
            <p className={additionalStyles.desc}>Are you sure you want to exit? All changes will be lost.</p>
            <div className={additionalStyles.separator} />
            <div className={additionalStyles.button_wrapper}>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={additionalStyles.dialogButtons}
                    onClick={exit}
                >
                    Yes, exit
                </ButtonMUI>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                    onClick={onClose}
                >
                    No, continue
                </ButtonMUI>
            </div>
        </div>
    </>
</DialogMUI>

export const ClearModal = ({ open, onClose, clear }) => <DialogMUI
    open={open}
    onClose={onClose}
    maxWidth={'xs'}
>
    <>
        <h2 className={additionalStyles.dialogTitle}>Clear all</h2>
        <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
            <p className={additionalStyles.desc}>Are you sure you want to clear all tracks from the list?</p>
            <div className={additionalStyles.separator} />
            <div className={additionalStyles.button_wrapper}>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={additionalStyles.dialogButtons}
                    onClick={clear}
                >
                    Yes, clear
                </ButtonMUI>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                    onClick={onClose}
                >
                    No, cancel
                </ButtonMUI>
            </div>
        </div>
    </>
</DialogMUI>

export const CopyModal = ({ open, onClose, setSelectedTracks }) => {
    const dispatch = useDispatch();
    const { pageType, pageID } = useParams()
    const { availablePacks } = useSelector(({ pagesContent }) => pagesContent)
    const [activeTab, setActiveTab] = useState(null)
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        setActiveTab(0)
    }, [availablePacks])

    const returnTabs = () => {
        if (availablePacks.length > 0) {
            return (<>
                <div className={classList(styles.copyTabsWrapper, styles.tabWrap)}>
                    <p>{availablePacks.length === 1 ? 'Only 1 option available' : 'Select the list'}</p>
                    <Tabs className={styles.tabs} defaultIndex={activeTab} onTabClick={setActiveTab} withoutInitialChangeIndex={activeTab}>
                        {availablePacks.map((el, idx) => <TabItem key={idx} label={el} index={idx + 1} />)}
                    </Tabs>
                </div>
            </>)
        } else {
            return (<>
                <div className={styles.noAvailableCopy}>
                    <InfoIcon />
                    <p>No list has been added yet</p>
                </div>
            </>)
        }
    }

    const copy = () => {
        setBtnLoad(true)
        dispatch(copyContent(pageType, pageID, `${availablePacks[activeTab - 1]}ly`))
            .then(res => {
                setBtnLoad(false)
                if (res.type.includes('_SUCCESS')) {
                    setSelectedTracks(res.payload.data.tracks)
                    onClose()
                }
            })
    }

    return <DialogMUI
        open={open}
        onClose={onClose}
        maxWidth={'xs'}
    >
        <>
            <h2 className={additionalStyles.dialogTitle}>Duplicate list</h2>
            <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
                <p className={additionalStyles.desc}>You can duplicate the top songs ranking for another time period, added manually or automatically.</p>
                {returnTabs()}
                <p className={additionalStyles.desc}>{
                    availablePacks.length > 0
                        ? 'You will also have the option to modify the list as desired.'
                        : 'Once the list is added, you can duplicate and modify it.'
                }</p>
                <div className={additionalStyles.separator} />
                <div className={additionalStyles.button_wrapper}>
                    {availablePacks.length > 0
                        ? <>
                            <ButtonMUI
                                variant='contained'
                                size="small"
                                className={additionalStyles.dialogButtons}
                                onClick={copy}
                                loading={btnLoad}
                                disabled={!activeTab}
                            >
                                Duplicate
                            </ButtonMUI>
                            <ButtonMUI
                                variant='contained'
                                size="small"
                                className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                                onClick={onClose}
                            >
                                Cancel
                            </ButtonMUI>
                        </>
                        : <ButtonMUI
                            variant='contained'
                            size="small"
                            className={additionalStyles.dialogButtons}
                            onClick={onClose}
                        >
                            Ok, got it
                        </ButtonMUI>
                    }
                </div>
            </div>
        </>
    </DialogMUI>
}